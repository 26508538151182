<template>
  <component :is="as" class="base-stack" :style="cssVars" v-on="$listeners">
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseStack extends Vue {
  @Prop({ default: 'div' })
  as!: string;

  @Prop({ default: 3 })
  public spacing!: number;

  @Prop({ default: 'column' })
  public direction!: 'column' | 'row';

  @Prop({ default: 'stretch' })
  public align!: 'start' | 'center' | 'end' | 'stretch';

  @Prop({ default: 'start' })
  public justify!: 'start' | 'center' | 'end' | 'stretch';

  get cssVars() {
    return {
      '--direction': this.direction,
      '--spacing': `${this.spacing / 4}rem`,
      '--align': this.align,
      '--justify': this.justify,
    };
  }
}
</script>

<style lang="scss" scoped>
.base-stack {
  display: flex;
  flex-direction: var(--direction);
  gap: var(--spacing);
  align-items: var(--align);
  justify-content: var(--justify);
}
</style>
