<template>
  <div class="stepper" :class="{ disabled: disabled }">
    <base-button
      @click="decrement"
      :disabled="disabled || value <= min"
      class="stepper__button stepper__button--decrement"
    >
      –
    </base-button>
    <span class="stepper__value">{{ value }}</span>
    <base-button
      @click="increment"
      :disabled="disabled || (max !== undefined && value >= max)"
      class="stepper__button stepper__button--increment"
    >
      +
    </base-button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'value',
    event: 'input',
  },
})
export default class BaseStepper extends Vue {
  @Prop({ required: true, type: Number })
  public value!: number;

  @Prop({ default: 0, type: Number })
  public min!: number;

  @Prop({ default: undefined, type: Number })
  public max?: number;

  @Prop({ default: 1, type: Number })
  public step!: number;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  public increment(): void {
    if (this.disabled || (this.max !== undefined && this.value >= this.max)) {
      return;
    }
    this.$emit('input', this.value + this.step);
    this.$emit('increment');
  }

  public decrement(): void {
    if (this.disabled || this.value <= this.min) {
      return;
    }
    this.$emit('input', this.value - this.step);
    this.$emit('decrement');
  }
}
</script>

<style lang="scss" scoped>
.stepper {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  gap: 0.3rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__value {
    flex: 1;
    text-align: center;
    color: $color-theme-yellow;
    font-size: 24px;
    font-weight: bold;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-theme-red;
  }

  &__button {
    flex: 1;

    &:not(:hover) {
      background-color: transparent !important;
    }

    color: $color-theme-red;

    max-width: 40px;
  }
}
</style>
