import enLocale from '@/locale/en.json';
import { AugmentedTranslator } from '@/utils/ai18n';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import 'core-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
import VueGtm from 'vue-gtm';
import VueI18n from 'vue-i18n';
import VueMq from 'vue-mq';
import createProvider from './apollo';
import App from './App.vue';
import Block from './Block.vue';
import EnvPlugin from './plugins/env';
import createRouter from './router';
import { agentDidAcceptCookies } from './utils/cookies';
import { getEnvironment } from './utils/getEnvironment';
import { importBaseComponents } from './utils/importBaseComponents';

dayjs.extend(utc);

Vue.config.productionTip = false;
Vue.use(Croppa);

importBaseComponents();

// Set up i18n and register augmented translator.
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en: enLocale },
});
Vue.use(AugmentedTranslator, i18n);

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    mobile: 650, // 450
    // md: 1250,
    desktop: Infinity,
  },
});

Vue.filter('dayjs', (value: any, format: string) => dayjs(value).format(format));
Vue.filter('dayjs.utc', (value: any, format: string) => dayjs.utc(value).format(format));

Vue.filter('duration', (value: number | null): string | null => {
  if (value) {
    const h = Math.floor(value / 3600);
    const m = Math.floor((value % 3600) / 60);
    const s = value % 60;
    return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter((a) => a).join(':');
  } else {
    return null;
  }
});

getEnvironment()
  .then((env) => {
    Sentry.init({
      dsn: env.SENTRY_DSN || undefined,
      integrations: [new Integrations.Vue({ Vue, attachProps: true })],
      environment: env.PIPELINE_STAGE || undefined,
      enabled: !!env.PIPELINE_STAGE,
    });

    const router = createRouter({
      invitationQuery: env.INVITATION_QUERY || undefined,
    });

    Vue.use(VueGtm, {
      id: env.GTM,
      vueRouter: router,
      enabled: agentDidAcceptCookies(),
      debug: !env.PIPELINE_STAGE,
    });

    Vue.use(EnvPlugin, env);

    createProvider().then((provider) => {
      new Vue({
        i18n,
        router,
        apolloProvider: provider,
        render: (h) => h(App),
      }).$mount('#app');
    });
  })
  .catch((e) => {
    console.error('Block', e);
    new Vue({
      render: (h) => h(Block),
    }).$mount('#app');
  });
