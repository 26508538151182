<template>
  <label class="base-form-input">
    <span class="base-form-input__label">
      {{ label }}
      <span v-if="optional" class="base-form-input__flag">* optional</span>
    </span>
    <transition name="shake">
      <ul class="base-form-input__errors" v-if="errors.length > 0">
        <li class="error" v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </transition>
    <vue-tel-input
      :value="value"
      v-bind="$attrs"
      :defaultCountry="defaultCountry"
      :disabledFetchingCountry="!!defaultCountry"
      wrapperClasses="telinput"
      inputClasses="input_field"
      dynamicPlaceholder
      @input="phoneCheck"
      @validate="isValid = $event.isValid"
      @open="onDropdownOpen"
      @close="onDropdownClose"
    />
    <span class="base-form-input__details" v-if="details">
      {{  details }}
    </span>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueTelInput, VueTelInputResponse } from 'vue-tel-input';

@Component({ components: { VueTelInput } })
export default class BaseFormTelInput extends Vue {
  @Prop({ required: true })
  public label!: string;
  
  @Prop()
  public details?: string;

  @Prop({ type: Boolean, default: false })
  public optional!: boolean;

  @Prop({ type: Array, default: () => [] })
  public errors!: string[];

  @Prop()
  public value?: any;

  @Prop({ default: 'US' })
  public defaultCountry!: string;

  showDropdown = false;

  isValid = false;

  onDropdownOpen() {
    this.showDropdown = !this.showDropdown;
  }

  onDropdownClose() {
    const el = this.$children[0].$refs.list as HTMLElement;
    this.$nextTick(() => {
      el.style.display = this.showDropdown ? 'block' : 'none';
    });
  }

  phoneCheck(num: string, obj: VueTelInputResponse) {
    this.$emit('input', obj);
  }
}
</script>

<style lang="scss" scoped>
.base-form-input {
  margin: 8px 0;
  transition: 0.1s;

  &:hover &__label {
    color: $color-theme-yellow;
  }

  &__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__flag {
    font-size: 18px;
    color: black !important;
  }

  &__errors {
    font-size: 16px;
    color: $color-theme-red;

    .error {
      margin: 4px 0;
    }
  }
  &__details {
    font-size: .8em;
  }

  .telinput {
    padding: 6px 8px;
    margin-top: 4px;
    font-size: 20px;
    width: 100%;
    background: $color-input;
    border: 2px solid black;
  }

  ::v-deep(.input_field) {
    background: $color-input;
    font-size: 20px;
    width: 100%;
  }
}
</style>
