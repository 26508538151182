<template>
  <label class="base-form-input">
    <span class="base-form-input__label" v-if="label">
      {{ label }}
      <span v-if="optional" class="base-form-input__flag">* optional</span>
    </span>
    <transition name="shake">
      <ul class="base-form-input__errors" v-if="errors.length > 0">
        <li class="error" v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </transition>
    <input
      :value="value"
      :minlength="minLength"
      :maxlength="maxLength"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
    />
    <span class="base-form-input__details" v-if="details">
      {{  details }}
    </span>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class BaseFormInput extends Vue {
  @Prop()
  public label!: string;

  @Prop()
  public details?: string;

  @Prop({ type: Boolean, default: false })
  public optional!: boolean;

  @Prop({ type: Array, default: () => [] })
  public errors!: string[];

  @Prop()
  public value: any;

  @Prop()
  public maxLength?: number;

  @Prop()
  public minLength?: number;
}
</script>

<style lang="scss" scoped>
.base-form-input {
  margin: 8px 0;
  transition: 0.1s;
  &:hover {
    color: $color-theme-yellow;
  }
  &__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__flag {
    font-size: 18px;
    color: black !important;
  }
  &__errors {
    font-size: 16px;
    color: $color-theme-red;
    .error {
      margin: 4px 0;
    }
  }
  &__details {
    font-size: .8em;
  }
  input {
    padding: 6px 8px;
    margin-top: 4px;
    font-size: 20px;
    width: 100%;
    background: $color-input;
    border: 2px solid black;
  }
}
</style>
