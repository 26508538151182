export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AboutDocument = IDocument & {
  __typename?: 'AboutDocument';
  id: Scalars['ID'];
  html: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** Restricted to admins */
  revisions: Array<AboutDocument>;
};

export type AddressData = {
  __typename?: 'AddressData';
  venue: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
};

export type AddSubtitleTrackInput = {
  assetId: Scalars['ID'];
  name: Scalars['String'];
  languageCode: Scalars['String'];
  file: Scalars['Upload'];
};

export type AddSubtitleTrackPayload = {
  __typename?: 'AddSubtitleTrackPayload';
  success: Scalars['Boolean'];
};

export type Blog = {
  __typename?: 'Blog';
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  video?: Maybe<Video>;
  liveStream?: Maybe<LiveStream>;
  chat?: Maybe<Chat>;
  /** Restricted to admins */
  sortOrder: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type BorderControl = {
  __typename?: 'BorderControl';
  /** Restricted to admins */
  earlyEntryLimit: Scalars['Int'];
  details?: Maybe<Scalars['String']>;
  isEarlyEntryAvailable: Scalars['Boolean'];
  earlyEntryCount: Scalars['Int'];
};

export type CancelCitizenshipSubscriptionPayload = {
  __typename?: 'CancelCitizenshipSubscriptionPayload';
  user: User;
};

export type ChangePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  user: User;
};

export type Chat = {
  __typename?: 'Chat';
  id: Scalars['ID'];
  disabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  comments: CommentsPage;
};


export type ChatCommentsArgs = {
  pagination?: Maybe<CommentsPageInput>;
};

export type ChatComment = {
  __typename?: 'ChatComment';
  id: Scalars['ID'];
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  userProfile?: Maybe<UserProfile>;
};

export type Citizenship = IPurchasable & {
  __typename?: 'Citizenship';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
  variants: Array<Variant>;
  monthlyPrice: Scalars['Int'];
  yearlyPrice: Scalars['Int'];
};

export type CitizenshipSubscription = {
  __typename?: 'CitizenshipSubscription';
  status: SubscriptionStatus;
  price: Scalars['Int'];
  interval: SubscriptionInterval;
  currentPeriodEnd: Scalars['DateTime'];
  willCancelAt?: Maybe<Scalars['DateTime']>;
  latestInvoiceUrl?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type CommentAddedPayload = {
  __typename?: 'CommentAddedPayload';
  comment: ChatComment;
};

export type CommentDeletedPayload = {
  __typename?: 'CommentDeletedPayload';
  id: Scalars['ID'];
};

export type CommentsPage = {
  __typename?: 'CommentsPage';
  data: Array<ChatComment>;
  hasMore: Scalars['Boolean'];
};

export type CommentsPageInput = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};

export type CompletePhotoUploadInput = {
  id: Scalars['ID'];
};

export type CompletePhotoUploadPayload = {
  __typename?: 'CompletePhotoUploadPayload';
  showPhoto: ShowPhoto;
};

export type ConfirmMeetAndGreetTicketInput = {
  id: Scalars['ID'];
  extraTickets?: Maybe<ExtraTicketsPurchase>;
  bundle?: Maybe<MeetAndGreetBundlePurchase>;
  termsId: Scalars['ID'];
  items: Array<ConfirmMeetAndGreetTicketItemInput>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ConfirmMeetAndGreetTicketItemInput = {
  variantId: Scalars['ID'];
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
};

export type ConfirmMeetAndGreetTicketPayload = {
  __typename?: 'ConfirmMeetAndGreetTicketPayload';
  meetAndGreetTicket: MeetAndGreetTicket;
};

export type CookiesDocument = IDocument & {
  __typename?: 'CookiesDocument';
  id: Scalars['ID'];
  html: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** Restricted to admins */
  revisions: Array<CookiesDocument>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  name: Scalars['String'];
  provinces: Array<Province>;
};

export type CreateBlogInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  video?: Maybe<CreateVideoInput>;
  liveStream?: Maybe<CreateLiveStreamInput>;
  chat?: Maybe<CreateChatInput>;
};

export type CreateBlogPayload = {
  __typename?: 'CreateBlogPayload';
  blog: Blog;
};

export type CreateChatCommentInput = {
  chatId: Scalars['ID'];
  body: Scalars['String'];
};

export type CreateChatCommentPayload = {
  __typename?: 'CreateChatCommentPayload';
  comment: ChatComment;
};

export type CreateChatInput = {
  disabled?: Maybe<Scalars['Boolean']>;
};

export type CreateCitizenshipSubscriptionInput = {
  /** From stripe elements */
  paymentMethodId: Scalars['String'];
  interval: SubscriptionInterval;
};

export type CreateCitizenshipSubscriptionPayload = {
  __typename?: 'CreateCitizenshipSubscriptionPayload';
  user: User;
};

export type CreateLiveStreamInput = {
  isCitizenRestricted: Scalars['Boolean'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  simulcastsInput?: Maybe<Array<MuxSimulcastInput>>;
};

export type CreateMeetAndGreetProductInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  tourId: Scalars['String'];
  price: Scalars['Int'];
  loyalExclusive: Scalars['Boolean'];
  randomExclusive: Scalars['Boolean'];
  variantInputs: Array<VariantInput>;
  images?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateMeetAndGreetProductPayload = {
  __typename?: 'CreateMeetAndGreetProductPayload';
  product: MeetAndGreetProduct;
};

export type CreateMovieInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
  video: CreateVideoInput;
  published: Scalars['Boolean'];
};

export type CreateMoviePayload = {
  __typename?: 'CreateMoviePayload';
  movie: Movie;
};

export type CreatePaymentIntentInput = {
  /** From stripe elements */
  paymentMethodId: Scalars['String'];
  amountInCents: Scalars['Float'];
  currency: Currency;
};

export type CreatePaymentIntentPayload = {
  __typename?: 'CreatePaymentIntentPayload';
  paymentIntentId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type CreatePostInput = {
  title: Scalars['String'];
  html: Scalars['String'];
  isNotification?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['Upload']>;
};

export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  post: Post;
};

export type CreateProductInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  isCitizenRestricted: Scalars['Boolean'];
  requiredScore?: Maybe<Scalars['Int']>;
  variantInputs: Array<VariantInput>;
  images?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateProductPayload = {
  __typename?: 'CreateProductPayload';
  product: Product;
};

export type CreateQuestionInput = {
  title: Scalars['String'];
  body: Scalars['String'];
};

export type CreateQuestionPayload = {
  __typename?: 'CreateQuestionPayload';
  question: Question;
};

export type CreateRefundInput = {
  depositId: Scalars['ID'];
  credits: Scalars['Float'];
};

export type CreateRefundPayload = {
  __typename?: 'CreateRefundPayload';
  refund: Refund;
};

export type CreateShowAddressInput = {
  venue: Scalars['String'];
  city: Scalars['String'];
  countryId: Scalars['String'];
  administrativeArea?: Maybe<Scalars['String']>;
};

export type CreateShowAttendanceInput = {
  showId: Scalars['ID'];
  applyMeetAndGreet?: Maybe<Scalars['Boolean']>;
};

export type CreateShowAttendancePayload = {
  __typename?: 'CreateShowAttendancePayload';
  showAttendance: ShowAttendance;
};

export type CreateShowBorderControlInput = {
  earlyEntryLimit: Scalars['Int'];
  details?: Maybe<Scalars['String']>;
};

export type CreateShowInput = {
  description?: Maybe<Scalars['String']>;
  showDate: Scalars['DateTime'];
  /** Defaults to now */
  visibleFrom?: Maybe<Scalars['DateTime']>;
  externalTicketLink?: Maybe<Scalars['String']>;
  /** Provide an optional regex used to validate tickets */
  attendanceValidationPattern?: Maybe<Scalars['String']>;
  tourId: Scalars['String'];
  addressInput: CreateShowAddressInput;
  ticketSalesInput?: Maybe<CreateShowTicketSalesInput>;
  borderControlInput?: Maybe<CreateShowBorderControlInput>;
  meetAndGreetInput?: Maybe<CreateShowMeetAndGreetInput>;
  chatInput?: Maybe<CreateChatInput>;
};

export type CreateShowMeetAndGreetInput = {
  price: Scalars['Int'];
  loyalQuota: Scalars['Int'];
  randomQuota: Scalars['Int'];
  purchaseQuota: Scalars['Int'];
  purchaseQuotaNotificationThreshold: Scalars['Int'];
  releaseDate: Scalars['DateTime'];
  isApplicationsEnabled?: Maybe<Scalars['Boolean']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
};

export type CreateShowPayload = {
  __typename?: 'CreateShowPayload';
  show: Show;
};

export type CreateShowTicketSalesInput = {
  price: Scalars['Int'];
  limit: Scalars['Int'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
};

export type CreateTourInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateTourPayload = {
  __typename?: 'CreateTourPayload';
  tour: Tour;
};

export type CreateUserRemarkInput = {
  comment: Scalars['String'];
  targetUserId: Scalars['ID'];
};

export type CreateUserRemarkPayload = {
  __typename?: 'CreateUserRemarkPayload';
  remark: UserRemark;
};

export type CreateVideoInput = {
  fileKey: Scalars['String'];
  isCitizenRestricted: Scalars['Boolean'];
};

export enum Currency {
  Usd = 'USD'
}


export type DeclineMeetAndGreetTicketInput = {
  id: Scalars['ID'];
};

export type DeclineMeetAndGreetTicketPayload = {
  __typename?: 'DeclineMeetAndGreetTicketPayload';
  meetAndGreetTicket: MeetAndGreetTicket;
};

export type DeleteBlogInput = {
  id: Scalars['ID'];
};

export type DeleteBlogPayload = {
  __typename?: 'DeleteBlogPayload';
  success: Scalars['Boolean'];
};

export type DeleteChatCommentInput = {
  id: Scalars['ID'];
};

export type DeleteChatCommentPayload = {
  __typename?: 'DeleteChatCommentPayload';
  success: Scalars['Boolean'];
};

export type DeleteMeetAndGreetProductInput = {
  id: Scalars['ID'];
};

export type DeleteMeetAndGreetProductPayload = {
  __typename?: 'DeleteMeetAndGreetProductPayload';
  success: Scalars['Boolean'];
};

export type DeleteMovieInput = {
  id: Scalars['ID'];
};

export type DeleteMoviePayload = {
  __typename?: 'DeleteMoviePayload';
  success: Scalars['Boolean'];
};

export type DeletePostInput = {
  id: Scalars['ID'];
};

export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  success: Scalars['Boolean'];
};

export type DeleteProductInput = {
  id: Scalars['ID'];
};

export type DeleteProductPayload = {
  __typename?: 'DeleteProductPayload';
  success: Scalars['Boolean'];
};

export type DeleteQuestionInput = {
  id: Scalars['ID'];
};

export type DeleteQuestionPayload = {
  __typename?: 'DeleteQuestionPayload';
  success: Scalars['Boolean'];
};

export type DeleteShowAttendanceInput = {
  showId: Scalars['ID'];
};

export type DeleteShowAttendancePayload = {
  __typename?: 'DeleteShowAttendancePayload';
  success: Scalars['Boolean'];
};

export type DeleteShowInput = {
  id: Scalars['ID'];
};

export type DeleteShowPayload = {
  __typename?: 'DeleteShowPayload';
  success: Scalars['Boolean'];
};

export type DeleteShowPhotoInput = {
  id: Scalars['ID'];
};

export type DeleteShowPhotoPayload = {
  __typename?: 'DeleteShowPhotoPayload';
  success: Scalars['Boolean'];
};

export type DeleteSimulcastInput = {
  id: Scalars['ID'];
};

export type DeleteSimulcastPayload = {
  __typename?: 'DeleteSimulcastPayload';
  simulcast: MuxSimulcast;
};

export type DeleteSubtitleTrackInput = {
  assetId: Scalars['ID'];
  trackId: Scalars['String'];
};

export type DeleteSubtitleTrackPayload = {
  __typename?: 'DeleteSubtitleTrackPayload';
  success: Scalars['Boolean'];
};

export type DeleteTourInput = {
  id: Scalars['ID'];
};

export type DeleteTourPayload = {
  __typename?: 'DeleteTourPayload';
  success: Scalars['Boolean'];
};

export type DeleteUserInput = {
  password: Scalars['String'];
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  success: Scalars['Boolean'];
};

export type DeleteUserRemarkInput = {
  id: Scalars['ID'];
};

export type DeleteUserRemarkPayload = {
  __typename?: 'DeleteUserRemarkPayload';
  success: Scalars['Boolean'];
};

export type Deposit = ITransaction & {
  __typename?: 'Deposit';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  date: Scalars['DateTime'];
  amountInCents: Scalars['Int'];
  currency: Scalars['String'];
  receiptUrl?: Maybe<Scalars['String']>;
  refunds: Array<Refund>;
  creditsRefundable: Scalars['Int'];
  creditsRefunded: Scalars['Int'];
};

export type ExpressShipping = IPurchasable & {
  __typename?: 'ExpressShipping';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
  variants: Array<Variant>;
  earnableScore: Scalars['Int'];
  hasAnyVariantInStock: Scalars['Boolean'];
  /** Not available to US store */
  isAvailable: Scalars['Boolean'];
};

export type ExtraTicketsPurchase = {
  quantity: Scalars['Int'];
  price: Scalars['Int'];
};

export type IAddress = {
  city: Scalars['String'];
  administrativeArea?: Maybe<Scalars['String']>;
  country: Country;
};

export type IDocument = {
  id: Scalars['ID'];
  html: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type IEnvironment = {
  STRIPE_PUBLISHABLE_KEY?: Maybe<Scalars['String']>;
  GTM?: Maybe<Scalars['String']>;
  INVITATION_QUERY?: Maybe<Scalars['String']>;
  MUX_ENV_KEY?: Maybe<Scalars['String']>;
  SENTRY_DSN?: Maybe<Scalars['String']>;
  PIPELINE_STAGE?: Maybe<Scalars['String']>;
};

export type ImportedShow = {
  __typename?: 'ImportedShow';
  bitId: Scalars['String'];
  showDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  externalTicketLink?: Maybe<Scalars['String']>;
  address: AddressData;
};

export type ImportShowsInput = {
  tourId: Scalars['ID'];
};

export type ImportShowsPayload = {
  __typename?: 'ImportShowsPayload';
  shows: Array<ImportedShow>;
};

export type IOrderItem = {
  id: Scalars['ID'];
  credits: Scalars['Int'];
  order: Order;
  fulfillment?: Maybe<OrderFulfillment>;
  variant: Variant;
};

export type IPurchasable = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
  variants: Array<Variant>;
};

export type IssuedPassport = {
  __typename?: 'IssuedPassport';
  id: Scalars['ID'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type ITransaction = {
  id: Scalars['ID'];
  credits: Scalars['Int'];
  date: Scalars['DateTime'];
};

export type LiveStream = {
  __typename?: 'LiveStream';
  id: Scalars['ID'];
  status: LiveStreamStatus;
  streamKey: Scalars['String'];
  isCitizenRestricted: Scalars['Boolean'];
  scheduledAt: Scalars['DateTime'];
  streamUrl?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<MuxAsset>>;
  simulcasts: Array<MuxSimulcast>;
};

export enum LiveStreamStatus {
  Idle = 'Idle',
  Active = 'Active'
}

export type LiveStreamStatusChangedPayload = {
  __typename?: 'LiveStreamStatusChangedPayload';
  liveStream: LiveStream;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  user: User;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  success: Scalars['Boolean'];
};

export type MeetAndGreet = {
  __typename?: 'MeetAndGreet';
  price: Scalars['Int'];
  loyalQuota: Scalars['Int'];
  randomQuota: Scalars['Int'];
  /** Restricted to admins */
  purchaseQuota: Scalars['Int'];
  purchaseQuotaNotificationThreshold: Scalars['Int'];
  releaseDate: Scalars['DateTime'];
  releasedAt?: Maybe<Scalars['DateTime']>;
  instructions?: Maybe<Scalars['String']>;
  /** Restricted to admins */
  tickets: Array<MeetAndGreetTicket>;
  /** Restricted to admins */
  totalQuota: Scalars['Int'];
  isApplicationsEnabled: Scalars['Boolean'];
  isRemindersEnabled: Scalars['Boolean'];
  isReleased: Scalars['Boolean'];
  /** Restricted to admins */
  applicationsCount: Scalars['Int'];
  /** Restricted to admins */
  applications: Array<ShowAttendance>;
  /** Restricted to admins */
  leadingApplicants: Array<ShowAttendance>;
  numberOfValidTickets: Scalars['Int'];
  /** Restricted to admins */
  numberOfLoyalTickets: Scalars['Int'];
  /** Restricted to admins */
  numberOfRandomTickets: Scalars['Int'];
  /** Restricted to admins */
  numberOfTicketsPurchased: Scalars['Int'];
  numberOfUnpreparedTickets: Scalars['Int'];
  isSoldOut: Scalars['Boolean'];
  shouldDisplayPurchaseQuotaNotification: Scalars['Boolean'];
  winners: Array<UserProfile>;
  canValidate: Scalars['Boolean'];
  nextCode: Scalars['Float'];
};

export type MeetAndGreetBundle = {
  __typename?: 'MeetAndGreetBundle';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  tour: Tour;
  isDisabled: Scalars['Boolean'];
};

export type MeetAndGreetBundlePurchase = {
  price: Scalars['Int'];
};

export type MeetAndGreetPackage = {
  __typename?: 'MeetAndGreetPackage';
  items: Array<MeetAndGreetPackageItem>;
};

export type MeetAndGreetPackageItem = {
  __typename?: 'MeetAndGreetPackageItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  quantity: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
};

export type MeetAndGreetProduct = IPurchasable & {
  __typename?: 'MeetAndGreetProduct';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
  variants: Array<Variant>;
  /** Restricted to admins */
  sortOrder: Scalars['Int'];
  /** Restricted to admins */
  loyalExclusive: Scalars['Boolean'];
  /** Restricted to admins */
  randomExclusive: Scalars['Boolean'];
  earnableScore: Scalars['Int'];
  hasAnyVariantInStock: Scalars['Boolean'];
};

export enum MeetAndGreetQuota {
  Random = 'Random',
  Loyal = 'Loyal',
  Purchase = 'Purchase'
}

export type MeetAndGreetTermsDocument = IDocument & {
  __typename?: 'MeetAndGreetTermsDocument';
  id: Scalars['ID'];
  html: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** Restricted to admins */
  revisions: Array<MeetAndGreetTermsDocument>;
};

export type MeetAndGreetTicket = {
  __typename?: 'MeetAndGreetTicket';
  id: Scalars['ID'];
  quota: MeetAndGreetQuota;
  emailedAt?: Maybe<Scalars['DateTime']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['Float']>;
  declinedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  preparedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  showId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  orderId?: Maybe<Scalars['ID']>;
  show?: Maybe<Show>;
  user?: Maybe<User>;
  order?: Maybe<Order>;
  purchases: Array<MeetAndGreetTicketPurchase>;
  instructions?: Maybe<Scalars['String']>;
  products: Array<MeetAndGreetProduct>;
  isValidFor: Scalars['Float'];
  hasPurchasedBundle: Scalars['Boolean'];
  canPurchaseBundle: Scalars['Boolean'];
  package: MeetAndGreetPackage;
};

export type MeetAndGreetTicketPurchase = ITransaction & {
  __typename?: 'MeetAndGreetTicketPurchase';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  date: Scalars['DateTime'];
  quantity: Scalars['Int'];
  type: MeetAndGreetTicketPurchaseType;
  refundedAt?: Maybe<Scalars['DateTime']>;
  total: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  ticketId: Scalars['ID'];
  ticket: MeetAndGreetTicket;
  earnedScore: Scalars['Int'];
};

export enum MeetAndGreetTicketPurchaseType {
  Ticket = 'Ticket',
  Bundle = 'Bundle'
}

export type MessageError = {
  __typename?: 'MessageError';
  userId: Scalars['ID'];
  method: MessageMethod;
  message: Scalars['String'];
};

export enum MessageMethod {
  Email = 'Email',
  Sms = 'Sms'
}

export type MessageShowParticipantsInput = {
  showId: Scalars['ID'];
  message: Scalars['String'];
  userIds: Array<Scalars['ID']>;
  methods: Array<MessageMethod>;
};

export type MessageShowParticipantsPayload = {
  __typename?: 'MessageShowParticipantsPayload';
  exceptions: Array<MessageError>;
};

export type Movie = {
  __typename?: 'Movie';
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  video: Video;
  /** Restricted to admins */
  sortOrder: Scalars['Int'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  setShowAddress: SetShowAddressPayload;
  setUserAddress: SetUserAddressPayload;
  /** Restricted to admins */
  createBlog: CreateBlogPayload;
  /** Restricted to admins */
  updateBlog: UpdateBlogPayload;
  /** Restricted to admins */
  deleteBlog: DeleteBlogPayload;
  createChatComment: CreateChatCommentPayload;
  deleteChatComment: DeleteChatCommentPayload;
  createCitizenshipSubscription: CreateCitizenshipSubscriptionPayload;
  cancelCitizenship: CancelCitizenshipSubscriptionPayload;
  resumeCitizenship: ResumeCitizenshipSubscriptionPayload;
  updateCitizenshipSubscriptionInterval: UpdateCitizenshipSubscriptionIntervalPayload;
  createPaymentIntent: CreatePaymentIntentPayload;
  /** Restricted to admins */
  updateAbout: UpdateAboutPayload;
  /** Restricted to admins */
  updateCookies: UpdateCookiesPayload;
  /** Restricted to admins */
  updateMeetAndGreetTerms: UpdateMeetAndGreetTermsPayload;
  /** Restricted to admins */
  updateTerms: UpdateTermsPayload;
  revokePassport: RevokePassportPayload;
  /** Restricted to admins */
  setMeetAndGreetBundle: SetMeetAndGreetBundlePayload;
  purchaseMeetAndGreetTicket: PurchaseMeetAndGreetTicketPayload;
  confirmMeetAndGreetTicket: ConfirmMeetAndGreetTicketPayload;
  declineMeetAndGreetTicket: DeclineMeetAndGreetTicketPayload;
  prepareMeetAndGreetTicket: PrepareMeetAndGreetTicketPayload;
  useMeetAndGreetTicket: UseMeetAndGreetTicketPayload;
  refundMeetAndGreetTicket: RefundMeetAndGreetTicketPayload;
  /** Restricted to admins */
  createMovie: CreateMoviePayload;
  /** Restricted to admins */
  updateMovie: UpdateMoviePayload;
  /** Restricted to admins */
  deleteMovie: DeleteMoviePayload;
  addSubtitleTrack: AddSubtitleTrackPayload;
  deleteSubtitleTrack: DeleteSubtitleTrackPayload;
  /** Restricted to admins */
  deleteSimulcast: DeleteSimulcastPayload;
  purchaseProducts: PurchaseProductsPayload;
  purchasePhotos: PurchasePhotosPayload;
  purchasePassport: PurchasePassportPayload;
  refundOrder: RefundOrderPayload;
  /** Restricted to admins */
  createPost: CreatePostPayload;
  /** Restricted to admins */
  updatePost: UpdatePostPayload;
  /** Restricted to admins */
  deletePost: DeletePostPayload;
  updateCitizenship: UpdateCitizenshipPayload;
  /** Restricted to admins */
  updatePurchasable: UpdatePurchasablePayload;
  /** Restricted to admins */
  createMeetAndGreetProduct: CreateMeetAndGreetProductPayload;
  /** Restricted to admins */
  deleteMeetAndGreetProduct: DeleteMeetAndGreetProductPayload;
  /** Restricted to admins */
  createProduct: CreateProductPayload;
  /** Restricted to admins */
  deleteProduct: DeleteProductPayload;
  /** Restricted to admins */
  createQuestion: CreateQuestionPayload;
  /** Restricted to admins */
  updateQuestion: UpdateQuestionPayload;
  /** Restricted to admins */
  deleteQuestion: DeleteQuestionPayload;
  /** Restricted to admins */
  createRefund: CreateRefundPayload;
  releaseMeetAndGreet: ReleaseMeetAndGreetPayload;
  /** Restricted to admins */
  importShows: ImportShowsPayload;
  /** Restricted to admins */
  createShow: CreateShowPayload;
  /** Restricted to admins */
  updateShow: UpdateShowPayload;
  /** Restricted to admins */
  deleteShow: DeleteShowPayload;
  createShowAttendance: CreateShowAttendancePayload;
  verifyShowAttendance: VerifyShowAttendancePayload;
  updateShowAttendance: UpdateShowAttendancePayload;
  deleteShowAttendance: DeleteShowAttendancePayload;
  messageShowParticipants: MessageShowParticipantsPayload;
  uploadShowPhotos: UploadShowPhotosPayload;
  completePhotoUpload: CompletePhotoUploadPayload;
  deleteShowPhoto: DeleteShowPhotoPayload;
  purchaseTicket: PurchaseTicketPayload;
  refundTicket: RefundTicketPayload;
  useTicket: UseTicketPayload;
  revertTicketUse: RevertTicketUsePayload;
  /** Restricted to admins */
  createTour: CreateTourPayload;
  /** Restricted to admins */
  updateTour: UpdateTourPayload;
  /** Restricted to admins */
  deleteTour: DeleteTourPayload;
  register: RegisterPayload;
  login: LoginPayload;
  logout: LogoutPayload;
  requestPasswordReset: RequestPasswordResetPayload;
  resetPassword: ResetPasswordPayload;
  requestEmailVerification: RequestEmailVerificationPayload;
  verify: VerifyPayload;
  updateViewer: UpdateViewerPayload;
  changePassword: ChangePasswordPayload;
  deleteUser: DeleteUserPayload;
  createUserRemark: CreateUserRemarkPayload;
  updateUserRemark: UpdateUserRemarkPayload;
  deleteUserRemark: DeleteUserRemarkPayload;
};


export type MutationSetShowAddressArgs = {
  input: SetShowAddressInput;
};


export type MutationSetUserAddressArgs = {
  input: SetUserAddressInput;
};


export type MutationCreateBlogArgs = {
  input: CreateBlogInput;
};


export type MutationUpdateBlogArgs = {
  input: UpdateBlogInput;
};


export type MutationDeleteBlogArgs = {
  input: DeleteBlogInput;
};


export type MutationCreateChatCommentArgs = {
  input: CreateChatCommentInput;
};


export type MutationDeleteChatCommentArgs = {
  input: DeleteChatCommentInput;
};


export type MutationCreateCitizenshipSubscriptionArgs = {
  input: CreateCitizenshipSubscriptionInput;
};


export type MutationUpdateCitizenshipSubscriptionIntervalArgs = {
  input: UpdateCitizenshipSubscriptionIntervalInput;
};


export type MutationCreatePaymentIntentArgs = {
  input: CreatePaymentIntentInput;
};


export type MutationUpdateAboutArgs = {
  input: UpdateAboutInput;
};


export type MutationUpdateCookiesArgs = {
  input: UpdateCookiesInput;
};


export type MutationUpdateMeetAndGreetTermsArgs = {
  input: UpdateMeetAndGreetTermsInput;
};


export type MutationUpdateTermsArgs = {
  input: UpdateTermsInput;
};


export type MutationSetMeetAndGreetBundleArgs = {
  input: SetMeetAndGreetBundleInput;
};


export type MutationPurchaseMeetAndGreetTicketArgs = {
  input: PurchaseMeetAndGreetTicketInput;
};


export type MutationConfirmMeetAndGreetTicketArgs = {
  input: ConfirmMeetAndGreetTicketInput;
};


export type MutationDeclineMeetAndGreetTicketArgs = {
  input: DeclineMeetAndGreetTicketInput;
};


export type MutationPrepareMeetAndGreetTicketArgs = {
  input: PrepareMeetAndGreetTicketInput;
};


export type MutationUseMeetAndGreetTicketArgs = {
  input: UseMeetAndGreetTicketInput;
};


export type MutationRefundMeetAndGreetTicketArgs = {
  input: RefundMeetAndGreetTicketInput;
};


export type MutationCreateMovieArgs = {
  input: CreateMovieInput;
};


export type MutationUpdateMovieArgs = {
  input: UpdateMovieInput;
};


export type MutationDeleteMovieArgs = {
  input: DeleteMovieInput;
};


export type MutationAddSubtitleTrackArgs = {
  input: AddSubtitleTrackInput;
};


export type MutationDeleteSubtitleTrackArgs = {
  input: DeleteSubtitleTrackInput;
};


export type MutationDeleteSimulcastArgs = {
  input: DeleteSimulcastInput;
};


export type MutationPurchaseProductsArgs = {
  input: PurchaseProductsInput;
};


export type MutationPurchasePhotosArgs = {
  input: PurchasePhotosInput;
};


export type MutationPurchasePassportArgs = {
  input: PurchasePassportInput;
};


export type MutationRefundOrderArgs = {
  input: RefundOrderInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


export type MutationUpdateCitizenshipArgs = {
  input: UpdateCitizenshipInput;
};


export type MutationUpdatePurchasableArgs = {
  input: UpdatePurchasableInput;
};


export type MutationCreateMeetAndGreetProductArgs = {
  input: CreateMeetAndGreetProductInput;
};


export type MutationDeleteMeetAndGreetProductArgs = {
  input: DeleteMeetAndGreetProductInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};


export type MutationCreateQuestionArgs = {
  input: CreateQuestionInput;
};


export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
};


export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationReleaseMeetAndGreetArgs = {
  input: ReleaseMeetAndGreetInput;
};


export type MutationImportShowsArgs = {
  input: ImportShowsInput;
};


export type MutationCreateShowArgs = {
  input: CreateShowInput;
};


export type MutationUpdateShowArgs = {
  input: UpdateShowInput;
};


export type MutationDeleteShowArgs = {
  input: DeleteShowInput;
};


export type MutationCreateShowAttendanceArgs = {
  input: CreateShowAttendanceInput;
};


export type MutationVerifyShowAttendanceArgs = {
  input: VerifyShowAttendanceInput;
};


export type MutationUpdateShowAttendanceArgs = {
  input: UpdateShowAttendanceInput;
};


export type MutationDeleteShowAttendanceArgs = {
  input: DeleteShowAttendanceInput;
};


export type MutationMessageShowParticipantsArgs = {
  input: MessageShowParticipantsInput;
};


export type MutationUploadShowPhotosArgs = {
  input: UploadShowPhotosInput;
};


export type MutationCompletePhotoUploadArgs = {
  input: CompletePhotoUploadInput;
};


export type MutationDeleteShowPhotoArgs = {
  input: DeleteShowPhotoInput;
};


export type MutationPurchaseTicketArgs = {
  input: PurchaseTicketInput;
};


export type MutationRefundTicketArgs = {
  input: RefundTicketInput;
};


export type MutationUseTicketArgs = {
  input: UseTicketInput;
};


export type MutationRevertTicketUseArgs = {
  input: RevertTicketUseInput;
};


export type MutationCreateTourArgs = {
  input: CreateTourInput;
};


export type MutationUpdateTourArgs = {
  input: UpdateTourInput;
};


export type MutationDeleteTourArgs = {
  input: DeleteTourInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRequestEmailVerificationArgs = {
  input: RequestEmailVerificationInput;
};


export type MutationVerifyArgs = {
  input: VerifyInput;
};


export type MutationUpdateViewerArgs = {
  input: UpdateViewerInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationCreateUserRemarkArgs = {
  input: CreateUserRemarkInput;
};


export type MutationUpdateUserRemarkArgs = {
  input: UpdateUserRemarkInput;
};


export type MutationDeleteUserRemarkArgs = {
  input: DeleteUserRemarkInput;
};

export type MuxAsset = {
  __typename?: 'MuxAsset';
  id: Scalars['ID'];
  durationInSeconds?: Maybe<Scalars['Int']>;
  streamUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  subtitles: Array<MuxSubtitleTrack>;
};

export type MuxSimulcast = {
  __typename?: 'MuxSimulcast';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  streamKey: Scalars['String'];
  status: MuxSimulcastStatus;
  webhookStatus?: Maybe<MuxWebhookStatus>;
  createdAt: Scalars['DateTime'];
};

export type MuxSimulcastInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  streamKey: Scalars['String'];
};

export enum MuxSimulcastStatus {
  Idle = 'Idle',
  Starting = 'Starting',
  Broadcasting = 'Broadcasting',
  Errored = 'Errored'
}

export type MuxSubtitleTrack = {
  __typename?: 'MuxSubtitleTrack';
  id: Scalars['String'];
  name: Scalars['String'];
  languageCode: Scalars['String'];
  status: Scalars['String'];
};

export enum MuxWebhookStatus {
  ToBeCreated = 'ToBeCreated',
  ToBeDeleted = 'ToBeDeleted',
  ToBeUpdated = 'ToBeUpdated'
}

export type Order = ITransaction & {
  __typename?: 'Order';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  date: Scalars['DateTime'];
  refundedAt?: Maybe<Scalars['DateTime']>;
  expectedDeliveryAt?: Maybe<Scalars['DateTime']>;
  orderItems: Array<IOrderItem>;
  orderFulfillments: Array<OrderFulfillment>;
  user?: Maybe<User>;
  earnedScore: Scalars['Int'];
};

export type OrderCitizenship = IOrderItem & {
  __typename?: 'OrderCitizenship';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  order: Order;
  fulfillment?: Maybe<OrderFulfillment>;
  variant: Variant;
  name: Scalars['String'];
  includeCertificate: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type OrderExpress = IOrderItem & {
  __typename?: 'OrderExpress';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  order: Order;
  fulfillment?: Maybe<OrderFulfillment>;
  variant: Variant;
  total: Scalars['Int'];
};

export type OrderFulfillment = {
  __typename?: 'OrderFulfillment';
  id: Scalars['ID'];
  trackingUrl?: Maybe<Scalars['String']>;
  orderItems: Array<IOrderItem>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrderMeetAndGreetProduct = IOrderItem & {
  __typename?: 'OrderMeetAndGreetProduct';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  order: Order;
  fulfillment?: Maybe<OrderFulfillment>;
  variant: Variant;
  quantity: Scalars['Int'];
  total: Scalars['Int'];
};

export type OrderPassport = IOrderItem & {
  __typename?: 'OrderPassport';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  order: Order;
  fulfillment?: Maybe<OrderFulfillment>;
  variant: Variant;
  lastName: Scalars['String'];
  givenNames: Scalars['String'];
  placeOfBirth: Scalars['String'];
  imageKey: Scalars['String'];
  total: Scalars['Int'];
};

export type OrderPhoto = IOrderItem & {
  __typename?: 'OrderPhoto';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  order: Order;
  fulfillment?: Maybe<OrderFulfillment>;
  variant: Variant;
  quantity: Scalars['Int'];
  photo: ShowPhoto;
  printedAt?: Maybe<Scalars['DateTime']>;
  total: Scalars['Int'];
};

export type OrderProduct = IOrderItem & {
  __typename?: 'OrderProduct';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  order: Order;
  fulfillment?: Maybe<OrderFulfillment>;
  variant: Variant;
  quantity: Scalars['Int'];
  total: Scalars['Int'];
};

export type Passport = IPurchasable & {
  __typename?: 'Passport';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
  variants: Array<Variant>;
  earnableScore: Scalars['Int'];
  hasAnyVariantInStock: Scalars['Boolean'];
};

export type PhotoInput = {
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type PhotoPrint = IPurchasable & {
  __typename?: 'PhotoPrint';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
  variants: Array<Variant>;
  earnableScore: Scalars['Int'];
  hasAnyVariantInStock: Scalars['Boolean'];
};

export type PhotoUpload = {
  __typename?: 'PhotoUpload';
  id: Scalars['ID'];
  filename: Scalars['String'];
  presignedUrl: Scalars['String'];
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  title: Scalars['String'];
  html: Scalars['String'];
  isNotification: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type PrepareMeetAndGreetTicketInput = {
  id: Scalars['ID'];
  code: Scalars['Float'];
};

export type PrepareMeetAndGreetTicketPayload = {
  __typename?: 'PrepareMeetAndGreetTicketPayload';
  meetAndGreetTicket: MeetAndGreetTicket;
};

export type Product = IPurchasable & {
  __typename?: 'Product';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  imageUrls: Array<Scalars['String']>;
  variants: Array<Variant>;
  isCitizenRestricted: Scalars['Boolean'];
  requiredScore: Scalars['Float'];
  /** Restricted to admins */
  sortOrder: Scalars['Int'];
  earnableScore: Scalars['Int'];
  hasAnyVariantInStock: Scalars['Boolean'];
};

export type Province = {
  __typename?: 'Province';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PurchaseMeetAndGreetTicketInput = {
  price: Scalars['Int'];
  showId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  termsId: Scalars['ID'];
  items: Array<PurchaseMeetAndGreetTicketItemInput>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseMeetAndGreetTicketItemInput = {
  variantId: Scalars['ID'];
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
};

export type PurchaseMeetAndGreetTicketPayload = {
  __typename?: 'PurchaseMeetAndGreetTicketPayload';
  meetAndGreetTicket: MeetAndGreetTicket;
};

export type PurchasePassportInput = {
  /** Express shipping cost in credits */
  expressShippingPrice?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  lastName: Scalars['String'];
  givenNames: Scalars['String'];
  placeOfBirth: Scalars['String'];
};

export type PurchasePassportPayload = {
  __typename?: 'PurchasePassportPayload';
  order: Order;
};

export type PurchasePhotosInput = {
  /** Express shipping cost in credits */
  expressShippingPrice?: Maybe<Scalars['Int']>;
  items: Array<PurchasePhotosItemInput>;
};

export type PurchasePhotosItemInput = {
  variantId: Scalars['ID'];
  photoId: Scalars['ID'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type PurchasePhotosPayload = {
  __typename?: 'PurchasePhotosPayload';
  order: Order;
};

export type PurchaseProductsInput = {
  /** Express shipping cost in credits */
  expressShippingPrice?: Maybe<Scalars['Int']>;
  items: Array<PurchaseProductsItemInput>;
};

export type PurchaseProductsItemInput = {
  variantId: Scalars['ID'];
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
};

export type PurchaseProductsPayload = {
  __typename?: 'PurchaseProductsPayload';
  order: Order;
};

export type PurchaseTicketInput = {
  price: Scalars['Int'];
  showId: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseTicketPayload = {
  __typename?: 'PurchaseTicketPayload';
  ticket: Ticket;
};

export type Query = {
  __typename?: 'Query';
  blogs: Array<Blog>;
  blog: Blog;
  activeLiveStream: Blog;
  chat?: Maybe<Chat>;
  countries: Array<Country>;
  country?: Maybe<Country>;
  about: AboutDocument;
  cookies: CookiesDocument;
  meetAndGreetTerms: MeetAndGreetTermsDocument;
  termsAndConditions: TermsDocument;
  webEnvironment: WebEnvironment;
  /** Restricted to admins */
  issuedPassport?: Maybe<IssuedPassport>;
  meetAndGreetTicket?: Maybe<MeetAndGreetTicket>;
  /** Restricted to admins */
  movies: Array<Movie>;
  movie: Movie;
  /** Restricted to admins */
  orders: Array<Order>;
  order?: Maybe<Order>;
  posts: Array<Post>;
  citizenship: Citizenship;
  expressShipping: ExpressShipping;
  passport: Passport;
  photoPrint: PhotoPrint;
  products: Array<Product>;
  questions: Array<Question>;
  show: Show;
  upcomingShows: Array<Show>;
  tours: Array<Tour>;
  tour?: Maybe<Tour>;
  viewer?: Maybe<User>;
  /** Restricted to admins */
  user?: Maybe<User>;
  /** Restricted to admins */
  userByToken?: Maybe<User>;
  /** Restricted to admins */
  totalUsersCount: Scalars['Int'];
  /** Restricted to admins */
  totalCitizensCount: Scalars['Int'];
  /** Restricted to admins */
  registeredUsersCount: Scalars['Int'];
  /** Restricted to admins */
  activeUsersCount: Scalars['Int'];
};


export type QueryBlogArgs = {
  id: Scalars['ID'];
};


export type QueryActiveLiveStreamArgs = {
  id: Scalars['ID'];
};


export type QueryChatArgs = {
  id: Scalars['ID'];
};


export type QueryCountryArgs = {
  id: Scalars['String'];
};


export type QueryIssuedPassportArgs = {
  id: Scalars['ID'];
};


export type QueryMeetAndGreetTicketArgs = {
  id: Scalars['ID'];
};


export type QueryMovieArgs = {
  id: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryShowArgs = {
  id: Scalars['ID'];
};


export type QueryTourArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserByTokenArgs = {
  token: Scalars['String'];
};


export type QueryRegisteredUsersCountArgs = {
  to?: Maybe<Scalars['DateTime']>;
  from: Scalars['DateTime'];
};


export type QueryActiveUsersCountArgs = {
  to?: Maybe<Scalars['DateTime']>;
  from: Scalars['DateTime'];
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  /** Restricted to admins */
  sortOrder: Scalars['Int'];
};

export type Refund = ITransaction & {
  __typename?: 'Refund';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  date: Scalars['DateTime'];
  amountInCents: Scalars['Int'];
  currency: Scalars['String'];
};

export type RefundMeetAndGreetTicketInput = {
  id: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type RefundMeetAndGreetTicketPayload = {
  __typename?: 'RefundMeetAndGreetTicketPayload';
  purchase: MeetAndGreetTicketPurchase;
};

export type RefundOrderInput = {
  id: Scalars['ID'];
};

export type RefundOrderPayload = {
  __typename?: 'RefundOrderPayload';
  order: Order;
};

export type RefundTicketInput = {
  id: Scalars['ID'];
};

export type RefundTicketPayload = {
  __typename?: 'RefundTicketPayload';
  ticket: Ticket;
};

export type RegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  preferredCountryId?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['ID']>;
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  user: User;
};

export type ReleaseMeetAndGreetInput = {
  showId: Scalars['String'];
};

export type ReleaseMeetAndGreetPayload = {
  __typename?: 'ReleaseMeetAndGreetPayload';
  show: Show;
};

export type RequestEmailVerificationInput = {
  email: Scalars['String'];
};

export type RequestEmailVerificationPayload = {
  __typename?: 'RequestEmailVerificationPayload';
  success: Scalars['Boolean'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  success: Scalars['Boolean'];
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  user: User;
};

export type ResumeCitizenshipSubscriptionPayload = {
  __typename?: 'ResumeCitizenshipSubscriptionPayload';
  user: User;
};

export type RevertTicketUseInput = {
  id: Scalars['ID'];
};

export type RevertTicketUsePayload = {
  __typename?: 'RevertTicketUsePayload';
  ticket: Ticket;
};

export type RevokePassportPayload = {
  __typename?: 'RevokePassportPayload';
  passport: IssuedPassport;
};

export type SetMeetAndGreetBundleInput = {
  tourId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  disabled?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<Scalars['Upload']>>;
};

export type SetMeetAndGreetBundlePayload = {
  __typename?: 'SetMeetAndGreetBundlePayload';
  bundle: MeetAndGreetBundle;
};

export type SetShowAddressInput = {
  showId: Scalars['ID'];
  venue: Scalars['String'];
  city: Scalars['String'];
  administrativeArea?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
};

export type SetShowAddressPayload = {
  __typename?: 'SetShowAddressPayload';
  show: Show;
};

export type SetUserAddressInput = {
  name: Scalars['String'];
  firstAddressLine: Scalars['String'];
  secondAddressLine?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
};

export type SetUserAddressPayload = {
  __typename?: 'SetUserAddressPayload';
  user: User;
};

export type Show = {
  __typename?: 'Show';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  /** Not UTC! Local time */
  showDate: Scalars['DateTime'];
  /** Restricted to admins */
  visibleFrom: Scalars['DateTime'];
  externalTicketLink?: Maybe<Scalars['String']>;
  /** Restricted to admins */
  attendanceValidationPattern?: Maybe<Scalars['String']>;
  requiresTicketVerification: Scalars['Boolean'];
  ticketSales?: Maybe<TicketSales>;
  borderControl?: Maybe<BorderControl>;
  meetAndGreet?: Maybe<MeetAndGreet>;
  tourId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  address: ShowAddress;
  photos: Array<ShowPhoto>;
  chat?: Maybe<Chat>;
  tour: Tour;
  attendanceCount: Scalars['Int'];
  attendance?: Maybe<ShowAttendance>;
  participants: Array<ShowParticipant>;
  showDateUtc: Scalars['DateTime'];
  isPast: Scalars['Boolean'];
};

export type ShowAddress = IAddress & {
  __typename?: 'ShowAddress';
  city: Scalars['String'];
  administrativeArea?: Maybe<Scalars['String']>;
  country: Country;
  venue: Scalars['String'];
};

export type ShowAttendance = {
  __typename?: 'ShowAttendance';
  showId: Scalars['ID'];
  userId: Scalars['ID'];
  id: Scalars['ID'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
  appliedMeetAndGreetAt?: Maybe<Scalars['DateTime']>;
  appliedEarlyEntryAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  show: Show;
  user: User;
  chat?: Maybe<Chat>;
};

export type ShowParticipant = {
  __typename?: 'ShowParticipant';
  id: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  showId: Scalars['ID'];
  attendance?: Maybe<ShowAttendance>;
  user?: Maybe<User>;
  show: Show;
  meetAndGreetTickets: Array<MeetAndGreetTicket>;
  tickets: Array<Ticket>;
  hasEarlyEntry: Scalars['Boolean'];
  photos: Array<ShowPhoto>;
};

export type ShowPhoto = {
  __typename?: 'ShowPhoto';
  id: Scalars['ID'];
  photographer: Scalars['String'];
  isMeetAndGreet: Scalars['Boolean'];
  imageKey: Scalars['String'];
  createdAt: Scalars['DateTime'];
  show?: Maybe<Show>;
  url: Scalars['String'];
  ordered: Array<OrderPhoto>;
};

export type SimulcastStatusChangedPayload = {
  __typename?: 'SimulcastStatusChangedPayload';
  muxSimulcast: MuxSimulcast;
};

export type Subscription = {
  __typename?: 'Subscription';
  commentAdded: CommentAddedPayload;
  commentDeleted: CommentDeletedPayload;
  liveStreamStatusChanged: LiveStreamStatusChangedPayload;
  simulcastStatusChanged: SimulcastStatusChangedPayload;
};


export type SubscriptionCommentAddedArgs = {
  id: Scalars['ID'];
};


export type SubscriptionCommentDeletedArgs = {
  id: Scalars['ID'];
};

export enum SubscriptionInterval {
  Month = 'Month',
  Year = 'Year'
}

export enum SubscriptionStatus {
  Active = 'Active',
  Incomplete = 'Incomplete',
  RequiresAction = 'RequiresAction',
  Trial = 'Trial'
}

export type TermsDocument = IDocument & {
  __typename?: 'TermsDocument';
  id: Scalars['ID'];
  html: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** Restricted to admins */
  revisions: Array<TermsDocument>;
  /** Restricted to admins */
  acceptedCount: Scalars['Int'];
};

export type Ticket = ITransaction & {
  __typename?: 'Ticket';
  id: Scalars['ID'];
  credits: Scalars['Int'];
  date: Scalars['DateTime'];
  refundedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  showId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  show: Show;
  user?: Maybe<User>;
  earnedScore: Scalars['Int'];
};

export type TicketSales = {
  __typename?: 'TicketSales';
  price: Scalars['Int'];
  /** Restricted to admins */
  limit: Scalars['Int'];
  availableFrom: Scalars['DateTime'];
  availableUntil?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
  isAvailable: Scalars['Boolean'];
  ticketsCount: Scalars['Int'];
  /** Restricted to admins */
  tickets: Array<Ticket>;
  hasOpened: Scalars['Boolean'];
  hasClosed: Scalars['Boolean'];
  canValidate: Scalars['Boolean'];
};

export type Tour = {
  __typename?: 'Tour';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  shows: Array<Show>;
  meetAndGreet?: Maybe<TourMeetAndGreet>;
  minDate?: Maybe<Scalars['DateTime']>;
  maxDate?: Maybe<Scalars['DateTime']>;
};


export type TourShowsArgs = {
  to?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['DateTime']>;
};

export type TourMeetAndGreet = {
  __typename?: 'TourMeetAndGreet';
  ticketTitle: Scalars['String'];
  ticketDescription?: Maybe<Scalars['String']>;
  ticketSummary: Scalars['String'];
  ticketImageUrls: Array<Scalars['String']>;
  defaultTicketPrice?: Maybe<Scalars['Int']>;
  defaultLoyalQuota?: Maybe<Scalars['Int']>;
  defaultRandomQuota?: Maybe<Scalars['Int']>;
  defaultPurchaseQuota?: Maybe<Scalars['Int']>;
  defaultPurchaseQuotaNotificationThreshold?: Maybe<Scalars['Int']>;
  defaultReleaseDate?: Maybe<Scalars['DateTime']>;
  defaultInstructions?: Maybe<Scalars['String']>;
  products: Array<MeetAndGreetProduct>;
  bundle?: Maybe<MeetAndGreetBundle>;
  publicProducts: Array<MeetAndGreetProduct>;
  ticketsSold: Scalars['Int'];
  bundlesSold: Scalars['Int'];
  /** Restricted to admins */
  purchasesByVariant: Array<VariantPurchases>;
};

export type TourMeetAndGreetInput = {
  ticketImages?: Maybe<Array<Scalars['Upload']>>;
  ticketTitle?: Maybe<Scalars['String']>;
  ticketDescription?: Maybe<Scalars['String']>;
  ticketSummary?: Maybe<Scalars['String']>;
  defaultTicketPrice?: Maybe<Scalars['Int']>;
  defaultPurchaseQuotaNotificationThreshold?: Maybe<Scalars['Int']>;
  defaultReleaseDate?: Maybe<Scalars['DateTime']>;
  defaultInstructions?: Maybe<Scalars['String']>;
  defaultPurchaseQuota?: Maybe<Scalars['Int']>;
  defaultLoyalQuota?: Maybe<Scalars['Int']>;
  defaultRandomQuota?: Maybe<Scalars['Int']>;
};

export type UpdateAboutInput = {
  html: Scalars['String'];
};

export type UpdateAboutPayload = {
  __typename?: 'UpdateAboutPayload';
  about: AboutDocument;
};

export type UpdateBlogInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  video?: Maybe<UpdateVideoInput>;
  liveStream?: Maybe<UpdateLiveStreamInput>;
  chat?: Maybe<UpdateChatInput>;
  image?: Maybe<Scalars['Upload']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type UpdateBlogPayload = {
  __typename?: 'UpdateBlogPayload';
  blog: Blog;
};

export type UpdateChatInput = {
  disabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateCitizenshipInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  monthlyPrice?: Maybe<Scalars['Int']>;
  yearlyPrice?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Upload']>;
};

export type UpdateCitizenshipPayload = {
  __typename?: 'UpdateCitizenshipPayload';
  citizenship: Citizenship;
};

export type UpdateCitizenshipSubscriptionIntervalInput = {
  interval: SubscriptionInterval;
};

export type UpdateCitizenshipSubscriptionIntervalPayload = {
  __typename?: 'UpdateCitizenshipSubscriptionIntervalPayload';
  user: User;
};

export type UpdateCookiesInput = {
  html: Scalars['String'];
};

export type UpdateCookiesPayload = {
  __typename?: 'UpdateCookiesPayload';
  cookies: CookiesDocument;
};

export type UpdateLiveStreamInput = {
  isCitizenRestricted?: Maybe<Scalars['Boolean']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  simulcastsInput?: Maybe<Array<MuxSimulcastInput>>;
};

export type UpdateMeetAndGreetTermsInput = {
  html: Scalars['String'];
};

export type UpdateMeetAndGreetTermsPayload = {
  __typename?: 'UpdateMeetAndGreetTermsPayload';
  meetAndGreetTerms: MeetAndGreetTermsDocument;
};

export type UpdateMovieInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  video?: Maybe<UpdateVideoInput>;
  image?: Maybe<Scalars['Upload']>;
  sortOrder?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type UpdateMoviePayload = {
  __typename?: 'UpdateMoviePayload';
  movie: Movie;
};

export type UpdatePostInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  isNotification?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['Upload']>;
};

export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  post: Post;
};

export type UpdatePurchasableInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Scalars['Int']>;
  requiredScore?: Maybe<Scalars['Int']>;
  isCitizenRestricted?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<Scalars['Upload']>>;
  variantInputs: Array<VariantInput>;
  loyalExclusive?: Maybe<Scalars['Boolean']>;
  randomExclusive?: Maybe<Scalars['Boolean']>;
};

export type UpdatePurchasablePayload = {
  __typename?: 'UpdatePurchasablePayload';
  purchasable: IPurchasable;
};

export type UpdateQuestionInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type UpdateQuestionPayload = {
  __typename?: 'UpdateQuestionPayload';
  question: Question;
};

export type UpdateShowAddressInput = {
  venue?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
};

export type UpdateShowAttendanceInput = {
  showId: Scalars['ID'];
  applyMeetAndGreet?: Maybe<Scalars['Boolean']>;
  applyEarlyEntry?: Maybe<Scalars['Boolean']>;
};

export type UpdateShowAttendancePayload = {
  __typename?: 'UpdateShowAttendancePayload';
  showAttendance: ShowAttendance;
};

export type UpdateShowBorderControlInput = {
  earlyEntryLimit?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
};

export type UpdateShowInput = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  showDate?: Maybe<Scalars['DateTime']>;
  visibleFrom?: Maybe<Scalars['DateTime']>;
  externalTicketLink?: Maybe<Scalars['String']>;
  /** Provide an optional regex used to validate tickets */
  attendanceValidationPattern?: Maybe<Scalars['String']>;
  tourId?: Maybe<Scalars['String']>;
  addressInput?: Maybe<UpdateShowAddressInput>;
  borderControlInput?: Maybe<UpdateShowBorderControlInput>;
  ticketSalesInput?: Maybe<UpdateShowTicketSalesInput>;
  meetAndGreetInput?: Maybe<UpdateShowMeetAndGreetInput>;
  chatInput?: Maybe<UpdateChatInput>;
};

export type UpdateShowMeetAndGreetInput = {
  price?: Maybe<Scalars['Int']>;
  loyalQuota?: Maybe<Scalars['Int']>;
  randomQuota?: Maybe<Scalars['Int']>;
  purchaseQuota?: Maybe<Scalars['Int']>;
  purchaseQuotaNotificationThreshold?: Maybe<Scalars['Int']>;
  releaseDate?: Maybe<Scalars['DateTime']>;
  isApplicationsEnabled?: Maybe<Scalars['Boolean']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
};

export type UpdateShowPayload = {
  __typename?: 'UpdateShowPayload';
  show: Show;
};

export type UpdateShowTicketSalesInput = {
  price?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdateTermsInput = {
  html: Scalars['String'];
};

export type UpdateTermsPayload = {
  __typename?: 'UpdateTermsPayload';
  terms: TermsDocument;
};

export type UpdateTourInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  meetAndGreet?: Maybe<TourMeetAndGreetInput>;
};

export type UpdateTourPayload = {
  __typename?: 'UpdateTourPayload';
  tour?: Maybe<Tour>;
};

export type UpdateUserRemarkInput = {
  id: Scalars['ID'];
  comment: Scalars['String'];
};

export type UpdateUserRemarkPayload = {
  __typename?: 'UpdateUserRemarkPayload';
  remark: UserRemark;
};

export type UpdateVideoInput = {
  fileKey?: Maybe<Scalars['String']>;
  isCitizenRestricted?: Maybe<Scalars['Boolean']>;
};

export type UpdateViewerInput = {
  username?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};

export type UpdateViewerPayload = {
  __typename?: 'UpdateViewerPayload';
  user: User;
};


export type UploadShowPhotosInput = {
  showId: Scalars['ID'];
  photographer: Scalars['String'];
  isMeetAndGreet: Scalars['Boolean'];
  photos: Array<PhotoInput>;
};

export type UploadShowPhotosPayload = {
  __typename?: 'UploadShowPhotosPayload';
  photos: Array<PhotoUpload>;
};

export type UseMeetAndGreetTicketInput = {
  id: Scalars['ID'];
};

export type UseMeetAndGreetTicketPayload = {
  __typename?: 'UseMeetAndGreetTicketPayload';
  meetAndGreetTicket: MeetAndGreetTicket;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  citizenshipExpiresAt?: Maybe<Scalars['DateTime']>;
  citizenSince: Scalars['DateTime'];
  role: UserRole;
  consecutiveDaysAsCitizen: Scalars['Int'];
  wallet: Wallet;
  score?: Maybe<UserScore>;
  hasPassport: Scalars['Boolean'];
  /** Delivery address */
  address?: Maybe<UserAddress>;
  locale: Scalars['String'];
  /** Restricted to admins */
  stripeId?: Maybe<Scalars['String']>;
  /** Restricted to admins */
  verified: Scalars['Boolean'];
  /** Restricted to admins */
  createdAt: Scalars['DateTime'];
  /** Restricted to admins */
  updatedAt: Scalars['DateTime'];
  /** Restricted to admins */
  citizenshipRemindedAt?: Maybe<Scalars['DateTime']>;
  /** Restricted to admins */
  notificationsLastReadAt?: Maybe<Scalars['DateTime']>;
  remarks: Array<UserRemark>;
  token: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  orders: Array<Order>;
  notifications: Array<Post>;
  /** Use as default country option, overridden by address */
  preferredCountry: Country;
  shows: Array<ShowParticipant>;
  /** @deprecated Use meetAndGreetToConfirm instead */
  requireMeetAndGreetAction: Scalars['Boolean'];
  meetAndGreetToConfirm?: Maybe<MeetAndGreetTicket>;
  hasStripeId: Scalars['Boolean'];
  stripeUrl?: Maybe<Scalars['String']>;
  citizenshipSubscription?: Maybe<CitizenshipSubscription>;
};


export type UserStripeUrlArgs = {
  returnUrl?: Maybe<Scalars['String']>;
};

export type UserAddress = IAddress & {
  __typename?: 'UserAddress';
  city: Scalars['String'];
  administrativeArea?: Maybe<Scalars['String']>;
  country: Country;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  firstAddressLine: Scalars['String'];
  secondAddressLine?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  id: Scalars['ID'];
  username: Scalars['String'];
  role: UserRole;
};

export type UserRemark = {
  __typename?: 'UserRemark';
  id: Scalars['ID'];
  authorId: Scalars['ID'];
  author: User;
  targetUserId: Scalars['ID'];
  targetUser: User;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum UserRole {
  Admin = 'Admin',
  Border = 'Border',
  Citizen = 'Citizen',
  Tourist = 'Tourist'
}

export type UserScore = {
  __typename?: 'UserScore';
  total: Scalars['Int'];
  bonus: Scalars['Int'];
};

export type UseTicketInput = {
  id: Scalars['ID'];
};

export type UseTicketPayload = {
  __typename?: 'UseTicketPayload';
  ticket: Ticket;
};

export type Variant = {
  __typename?: 'Variant';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Restricted to admins */
  shopifyIdEU?: Maybe<Scalars['ID']>;
  /** Restricted to admins */
  outOfStockEU: Scalars['Boolean'];
  /** Restricted to admins */
  shopifyIdUS?: Maybe<Scalars['ID']>;
  /** Restricted to admins */
  outOfStockUS: Scalars['Boolean'];
  /** Restricted to admins */
  sortOrder: Scalars['Int'];
  isInStock: Scalars['Boolean'];
  purchasable?: Maybe<IPurchasable>;
};

export type VariantInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  shopifyIdEU?: Maybe<Scalars['ID']>;
  shopifyIdUS?: Maybe<Scalars['ID']>;
  outOfStockEU?: Maybe<Scalars['Boolean']>;
  outOfStockUS?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type VariantPurchases = {
  __typename?: 'VariantPurchases';
  variant: Variant;
  purchases: Scalars['Float'];
};

export type VerifyInput = {
  token: Scalars['String'];
};

export type VerifyPayload = {
  __typename?: 'VerifyPayload';
  user: User;
};

export type VerifyShowAttendanceInput = {
  showId: Scalars['ID'];
  ticketCode: Scalars['String'];
};

export type VerifyShowAttendancePayload = {
  __typename?: 'VerifyShowAttendancePayload';
  showAttendance: ShowAttendance;
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID'];
  fileKey: Scalars['String'];
  isCitizenRestricted: Scalars['Boolean'];
  /** Restricted to admins */
  url: Scalars['String'];
  asset: MuxAsset;
};

export type Wallet = {
  __typename?: 'Wallet';
  balance: Scalars['Int'];
  transactions: Array<ITransaction>;
  creditsPerUsdCent: Scalars['Int'];
};

export type WebEnvironment = IEnvironment & {
  __typename?: 'WebEnvironment';
  STRIPE_PUBLISHABLE_KEY?: Maybe<Scalars['String']>;
  GTM?: Maybe<Scalars['String']>;
  INVITATION_QUERY?: Maybe<Scalars['String']>;
  MUX_ENV_KEY?: Maybe<Scalars['String']>;
  SENTRY_DSN?: Maybe<Scalars['String']>;
  PIPELINE_STAGE?: Maybe<Scalars['String']>;
  radioUrl: Scalars['String'];
};
